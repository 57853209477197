import './App.css';
import Logo from './logo/logo';

function App() {
  return (
    <div>
      <Logo/>
    </div>
  );
}

export default App;
